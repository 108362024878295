import { environment } from "../../../environments/environment";
const VERSION = 'v1';
//For LOGIN
export const VVS_VOIS_API_LOGIN = `${environment.baseUrl}${VERSION}/login`;
export const VVS_VOIS_SSO_LOGIN = `https://vois-api.vvsindia.com/php-saml-master/demo1/index.php?sso`;
export const VVS_VOIS_SSO_LOGOUT = `https://vois-api.vvsindia.com/php-saml-master/demo1/index.php?slo`;

//For ACL
export const VVS_VOIS_GET_ACL = `${environment.baseUrl}${VERSION}/UserAcl/GetUserMenuList`;


//For PTW
export const VVS_VOIS_PTW_MASTER_LIST = `${environment.baseUrl}${VERSION}/Ptw/GetPtwMaster`;
export const VVS_VOIS_PTW_MASTER_CHECK_LIST = `${environment.baseUrl}${VERSION}/Ptw/GetPtwMasterCheckList`;
export const VVS_VOIS_PTW_LIST = `${environment.baseUrl}${VERSION}/Ptw/GetPtwList`
export const VVS_VOIS_PTW_GET_DETAILS_BY_PTW_ID = `${environment.baseUrl}${VERSION}/Ptw/GetPtwDetails`
export const VVS_VOIS_ADD_EDIT_PTW = `${environment.baseUrl}${VERSION}/Ptw/AddPtw`
export const VVS_VOIS_SERVER_TIME_FOR_LISTING = `${environment.baseUrl}${VERSION}/ServerDate`
export const VVS_VOIS_ACTIVITY_LOG_FOR_PTW = `${environment.baseUrl}${VERSION}/Ptw/GetActivityLog`
export const VVS_VOIS_GET_DETAILS_REPORT = `${environment.baseUrl}${VERSION}/Ptw/ptw-excel`
export const VVS_VOIS_GET_EXCEL_LIST = `${environment.baseUrl}${VERSION}/Ptw/ptw-list-export`
export const VVS_VOIS_DATE_TIME = `${environment.baseUrl}ServerDate`
export const VVS_VOIS_GET_CONTRACTOR_WORKER_LIST = `${environment.baseUrl}${VERSION}/Ptw/GetContractorWorkerList`


//FOR MASTER
//Worker
export const VVS_VOIS_MST_WORKER_LIST = `${environment.baseUrl}${VERSION}/Master/GetWorkerList`
export const VVS_VOIS_ADD_WORKER = `${environment.baseUrl}${VERSION}/Master/AddWorker`
export const VVS_VOIS_WORKER_DETAILS = `${environment.baseUrl}${VERSION}/Master/GetWorkerDetails`
export const VVS_VOIS_GET_CAPTCHA = `${environment.baseUrl}${VERSION}/Ptw/CaptchaGenerate`
//Location
export const VVS_VOIS_MST_LOCATION_LIST = `${environment.baseUrl}${VERSION}/Master/GetLocationList`
export const VVS_VOIS_LOCATION_DETAILS = `${environment.baseUrl}${VERSION}/Master/GetLocationDetails`
export const VVS_VOIS_ADD_LOCATION = `${environment.baseUrl}${VERSION}/Master/AddLocation`
//Contractor
export const VVS_VOIS_MST_CONTRACTOR_LIST = `${environment.baseUrl}${VERSION}/Master/GetContractorList`
export const VVS_VOIS_ADD_CONTRACTOR = `${environment.baseUrl}${VERSION}/Master/AddContractor`
export const VVS_VOIS_CONTRACTOR_DETAILS = `${environment.baseUrl}${VERSION}/Master/GetContractorDetails`
//Skil
export const VVS_VOIS_MST_SKIL_LIST = `${environment.baseUrl}${VERSION}/Master/GetSkillList`
export const VVS_VOIS_ADD_SKIL = `${environment.baseUrl}${VERSION}/Master/AddSkill`
export const VVS_VOIS_SKIL_DETAILS = `${environment.baseUrl}${VERSION}/Master/GetSkillDetails`
//Sop
export const VVS_VOIS_MST_SOP_LIST = `${environment.baseUrl}${VERSION}/Master/GetSOPPolicyList`
export const VVS_VOIS_ADD_SOP = `${environment.baseUrl}${VERSION}/Master/AddSOPPolicy`
export const VVS_VOIS_SOP_DETAILS = `${environment.baseUrl}${VERSION}/Master/GetSOPPolicyDetails`
//User
export const VVS_VOIS_MST_USER_MODULE = `${environment.baseUrl}${VERSION}/Master/GetUserAccessMasterList`
export const VVS_VOIS_MST_USER_LIST = `${environment.baseUrl}${VERSION}/Master/GetUserAccessList`
export const VVS_VOIS_ADD_USER = `${environment.baseUrl}${VERSION}/Master/AddUserAccess`
export const VVS_VOIS_USER_DETAILS = `${environment.baseUrl}${VERSION}/Master/GetUserAccessDetails`
//JOB
export const VVS_VOIS_MST_JOB_LIST = `${environment.baseUrl}${VERSION}/Master/GetJobList`
export const VVS_VOIS_ADD_JOB = `${environment.baseUrl}${VERSION}/Master/AddJob`
export const VVS_VOIS_JOB_DETAILS = `${environment.baseUrl}${VERSION}/Master/GetJobDetails`
//ACTIVITY
export const VVS_VOIS_MST_ACTIVITY_LIST = `${environment.baseUrl}${VERSION}/Master/GetActivityList`
export const VVS_VOIS_ADD_ACTIVITY = `${environment.baseUrl}${VERSION}/Master/AddActivity`
export const VVS_VOIS_ACTIVITY_DETAILS = `${environment.baseUrl}${VERSION}/Master/GetActivityDetails`
//RISK_RELATED
export const VVS_VOIS_MST_RISK_LIST = `${environment.baseUrl}${VERSION}/Master/GetRiskRelatedList`
export const VVS_VOIS_ADD_RISK = `${environment.baseUrl}${VERSION}/Master/AddRiskRelated`
export const VVS_VOIS_RISK_DETAILS = `${environment.baseUrl}${VERSION}/Master/GetRiskRelatedDetails`
//HIRA
//for Hira Master
export const VVS_VOIS_HIRA_MASTER_LIST = `${environment.baseUrl}${VERSION}/Hira/GetHiraMaster`;
//for Hira List
export const VVS_VOIS_HIRA_LIST = `${environment.baseUrl}${VERSION}/Hira/GetHiraList`;
export const VVS_VOIS_ADD_HIRA = `${environment.baseUrl}${VERSION}/Hira/AddHira`;
export const VVS_VOIS_ADD_HIRA_INFO = `${environment.baseUrl}${VERSION}/Hira/AddHiraInfo`;
export const VVS_VOIS_ADD_HIRA_ACM = `${environment.baseUrl}${VERSION}/Hira/AddHiraACM`;
export const VVS_VOIS_GET_HIRA_INFO = `${environment.baseUrl}${VERSION}/Hira/GetHiraInfo`;
export const VVS_VOIS_GET_ACTION_OWNER_LIST = `${environment.baseUrl}${VERSION}/Hira/GetHiraActionOwnerMasterList`;
export const VVS_VOIS_GET_HIRA_DETAILS_REPORT = `${environment.baseUrl}${VERSION}/Hira/hira-excel`;
export const VVS_VOIS_GET_HIRA_ACTIVITY_LOG = `${environment.baseUrl}${VERSION}/Hira/GetActivityLog`;
export const VVS_VOIS_GET_HIRA_DOWNLOADABLE_LIST = `${environment.baseUrl}${VERSION}/Hira/hira-list-export`;
export const VVS_VOIS_GET_HIRA_ACTIVITY_WISE_LOG = `${environment.baseUrl}${VERSION}/Hira/GetHiraActivityLog`;
export const VVS_VOIS_MANAGE_HIRA_DECLARATION = `${environment.baseUrl}${VERSION}/Hira/AddDeclarationM`;
//Inspection
export const VVS_VOIS_INSPECTION_MASTER_LIST = `${environment.baseUrl}${VERSION}/Inspection/GetInspectionMaster`;
export const VVS_VOIS_GET_INSPECTION_LIST = `${environment.baseUrl}${VERSION}/Inspection/GetInspectionList`;
export const VVS_VOIS_GET_INSPECTION_CHECKLIST = `${environment.baseUrl}${VERSION}/Inspection/GetInspectionMasterCheckList`;
export const VVS_VOIS_ADD_INSPECTION = `${environment.baseUrl}${VERSION}/Inspection/AddInspection`;
export const VVS_VOIS_GET_INSPECTION_DETAILS = `${environment.baseUrl}${VERSION}/Inspection/GetInspectionDetails`;
export const VVS_VOIS_GET_INSPECTION_DOWNLOADABLELIST = `${environment.baseUrl}${VERSION}/Inspection/GetInspectionDownloadableList`;
//Action Tracker
export const VVS_VOIS_ACTION_MASTER_LIST = `${environment.baseUrl}${VERSION}/Observation/GetObservationMaster`;
export const VVS_VOIS_ACTION_LIST = `${environment.baseUrl}${VERSION}/Observation/GetObservationList`;
export const VVS_VOIS_GET_OBS_DETAILS = `${environment.baseUrl}${VERSION}/Observation/GetObservationDetails`;
export const VVS_VOIS_ADD_OBS = `${environment.baseUrl}${VERSION}/Observation/AddObservation`;
export const VVS_VOIS_GET_OBS_DETAILS_REPORT = `${environment.baseUrl}${VERSION}/Observation/observation-excel`;
export const VVS_VOIS_GET_OBS_DOWNLOADABLE_LIST = `${environment.baseUrl}${VERSION}/Observation/observation-list-excel`;
export const VVS_VOIS_GET_OBS_REMARKS_LOG = `${environment.baseUrl}${VERSION}/Observation/GetObservationCommentLog`;
export const VVS_VOIS_GET_OBS_DETAILS_LOG = `${environment.baseUrl}${VERSION}/Observation/GetObservationActionTakenLog`;
export const VVS_VOIS_MANAGE_OBS_DECLARATION = `${environment.baseUrl}${VERSION}/Observation/AddDeclarationM`;

//Traning
export const VVS_VOIS_TRANING_MASTER_LIST = `${environment.baseUrl}${VERSION}/Training/GetTrainingMaster`;
export const VVS_VOIS_TRANING_ADD = `${environment.baseUrl}${VERSION}/Training/AddTraining`;
export const VVS_VOIS_TRANING_DETAILS = `${environment.baseUrl}${VERSION}/Training/GetTrainingDetails`;
export const VVS_VOIS_TRANING_CALENDER_LIST =  `${environment.baseUrl}${VERSION}/Training/GetTraininglistCal`;
export const VVS_VOIS_TRANING_ON_DEMAND =  `${environment.baseUrl}${VERSION}/Training/GetTraininglistOnDemand`;
export const VVS_VOIS_ADD_TRANING_CALENDAR =  `${environment.baseUrl}${VERSION}/Training/AddTrainingCal`;
export const VVS_VOIS_DOWNLOAD_TRAINING_CALENDAR_TEMPLATE =  `${environment.baseUrl}${VERSION}/Training/GetTrainigMasterExcel`;
export const VVS_VOIS_UPLOAD_PARTICIPANT_EXCEL =  `${environment.baseUrl}${VERSION}/Training/ParticipantUpload`;

//File Upload
export const VVS_VOIS_FILE_UPLOAD = `${environment.baseUrl}${VERSION}/FileUpload`;
//PTW DASHBORAD
export const VVS_VOIS_PTW_DASHBORAD =  `${environment.baseUrl}${VERSION}/Dashboard/GetDashboardPtwCounts`;
//HIRA DASHBORAD
export const VVS_VOIS_HIRA_DASHBORAD =  `${environment.baseUrl}${VERSION}/Dashboard/GetDashboardHiraCounts`;
//TRAINING DASHBORAD
export const VVS_VOIS_TRAINING_DASHBORAD =  `${environment.baseUrl}${VERSION}/Dashboard/GetDashboardTrainingCounts`;
//OBSERVATION
export const VVS_VOIS_OBSERVATION_DASHBORAD =  `${environment.baseUrl}${VERSION}/Dashboard/GetDashboardObservationCounts`;
//OBSERVATION
export const VVS_VOIS_INSPECTION_DASHBORAD =  `${environment.baseUrl}${VERSION}/Dashboard/GetDashboardInspectionCounts`;
//REPORTS
export const VVS_VOIS_PTW_REPORTS =  `${environment.baseUrl}${VERSION}/Report/GetPtwListReport`;
export const VVS_VOIS_HIRA_REPORTS =  `${environment.baseUrl}${VERSION}/Report/GetHiraListReport`;
export const VVS_VOIS_ACTION_TRACKER_REPORTS =  `${environment.baseUrl}${VERSION}/Report/GetObservationListReport`;
export const VVS_VOIS_TRAINING_REPORTS =  `${environment.baseUrl}${VERSION}/Report/GetTrainingListReport`;
export const VVS_VOIS_INSPECTION_REPORTS =  `${environment.baseUrl}${VERSION}/Report/GetInspectionListReport`;
export const VVS_VOIS_INSPECTION_REPORT_EXCEL =  `${environment.baseUrl}${VERSION}/Report/InspectionExcel`;
//CHARTS
export const VVS_VOIS_GET_PTW_CHARTS =  `${environment.baseUrl}${VERSION}/Chart/GetChartPtwCounts`;
export const VVS_VOIS_GET_INSP_CHARTS =  `${environment.baseUrl}${VERSION}/Chart/GetChartInspectionCounts`;
export const VVS_VOIS_GET_OBS_CHARTS =  `${environment.baseUrl}${VERSION}/Chart/GetChartObservationCounts`;
export const VVS_VOIS_GET_HIRA_CHARTS =  `${environment.baseUrl}${VERSION}/Chart/GetChartHiraCounts`;
//PDF
export const VVS_VOIS_GET_PTW_PDF =  `${environment.reportsUrl}report/ptw/pdf`;
export const VVS_VOIS_GET_INSP_PDF =  `${environment.reportsUrl}report/inspection/pdf`;
export const VVS_VOIS_GET_TRAINING_PDF =  `${environment.reportsUrl}report/training/pdf`;
export const VVS_VOIS_GET_OBSERVATION_PDF =  `${environment.reportsUrl}report/observation/pdf`;
