import { Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgxUiLoaderModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'vois_hsw_app';

  // @HostListener('document:keydown', ['$event'])

  // handleKeyboardEvent(e: KeyboardEvent) {
  //   if (e.key === 'F12') {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "I") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "C") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "J") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.key == "U") {
  //     return false;
  //   }
  //   return true;
  // }
  // constructor() {
  //   document.addEventListener('contextmenu', function (e) {
  //     e.preventDefault();
  //   });
  // }
}
